import dynamic from 'next/dynamic'
import type { FC } from 'react'

import useIsMobile from '@hooks/mediaQueries/useIsMobile'

import { Container, LinkDownload, Title, Wrapper } from './styles'

const QrCode = dynamic(() => import('@components/QrCode'))

type Props = {
  appStoreImageUrl?: string
  appStoreLink?: string
  googleStoreImageUrl?: string
  googleStoreLink?: string
  qrUrl?: string
  title?: string
}

export const MobileAppDownload: FC<Props> = ({
  appStoreImageUrl,
  appStoreLink,
  googleStoreImageUrl,
  googleStoreLink,
  qrUrl,
  title,
}) => {
  const isMobile = useIsMobile()

  return (
    <Container>
      {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}

      <Wrapper>
        {!isMobile && qrUrl && <QrCode url={qrUrl} />}
        <LinkDownload href={appStoreLink} target="_blank">
          <img alt="app store icon" src={appStoreImageUrl} />
        </LinkDownload>
        <LinkDownload href={googleStoreLink} target="_blank">
          <img alt="google store icon" src={googleStoreImageUrl} />
        </LinkDownload>
      </Wrapper>
    </Container>
  )
}
