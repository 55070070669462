import styled from 'styled-components'

import { Container as BlockContainer, LinkDownload, Title, Wrapper } from '@components/MobileAppDownload/styles'
import { Image as QrCode } from '@components/QrCode/styles'

export const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 72px;
  height: 160px;
  width: 100%;
  background-color: ${p => p.theme.colors.black};
  border-radius: ${p => p.theme.borderRadius.sm};
  overflow: hidden;

  ${p => p.theme.mediaQueries.desktopXS} {
    padding: 32px;
    height: auto;

    ${QrCode} {
      order: 0;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding: 16px;

    ${Wrapper} {
      flex-direction: column;
      row-gap: 16px;
    }
  }

  ${BlockContainer} {
    z-index: 1;
  }

  ${Title} {
    color: ${p => p.theme.colors.white};
  }

  ${LinkDownload} {
    &,
    &:active,
    &:not(:disabled):not(.ant-btn-disabled):active,
    &:focus,
    &:not(:disabled):not(.ant-btn-disabled):focus,
    &:hover,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${props => props.theme.colors.black};
      width: 180px;
    }
  }
`

export const BackgroundImage = styled.div<{ $url: string }>`
  position: absolute;
  inset: 0;
  z-index: 0;
  display: block;
  background-image: ${p => `url(${p.$url})`};
  filter: blur(10px);
`
